<template>
    <div class="schedule-progress">
        <div class="numbers">
            <div class="cross-line" />
            <div class="circle" :class="{ selected: selected === i }" v-for="i in [1, 2, 3]" :key="i" v-html="i" />
        </div>
        <div class="items">
            <div
                class="item"
                :class="{ inactive: selected > i }"
                v-for="i in [1, 2, 3]"
                :key="`progress_item_${i}`"
                v-html="$translate(`SCHEDULE_PROGRESS_${i}_${mode.toUpperCase()}`)"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ScheduleProgress',
    props: {
        selected: {
            type: Number,
            default: 1,
        },
        mode: {
            type: String,
        },
    },
}
</script>

<style scoped lang="scss">
.schedule-progress {
    display: flex;

    .items {
        margin-left: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .inactive {
            color: $grey-04;
        }
    }
    .numbers {
        position: relative;

        .cross-line {
            position: absolute;
            height: 100%;
            width: 1px;
            left: 50%;
            top: 0;
            border-right: 1px solid $grey-04;
        }
        .circle {
            @include spoqa-f-bold;
            @include center;
            width: 26px;
            height: 26px;
            position: relative;
            font-size: 14px;
            color: $grey-04;
            background: white;
            border: 1px solid $grey-04;
            padding: 0 8px;
            border-radius: 50%;

            &.selected {
                color: white;
                background: #111111;
                //border: 1px solid $blue-primary;
            }

            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }
    }
}
</style>
